<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <h1 class="mb-4">
                    {{ $t('eav_entity.self') }} #{{ uuid }}
                </h1>

                <v-card :loading="loading">
                    <v-card-text>
                        <ValidationObserver>
                            <template v-for="prop in properties">
                                <v-row>
                                    <v-col>
                                        <ValidationProvider ref="prop.value.value" :rules="{required: prop.required}" v-slot="{ errors, valid }">
                                            <TextInput v-if="prop.type === 'string'" :id="`${prop.id}`" :value="postData[prop.slug]" :label="prop.name" @input="onValueChange(prop, $event)" :error="!valid"></TextInput>
                                            <TextInput v-else-if="prop.type === 'number'" :id="`${prop.id}`" :value="postData[prop.slug]" :label="prop.name" type="number" @input="onValueChange(prop, $event)" :error="!valid"></TextInput>
                                            <SelectInput v-else-if="prop.type === 'list'" :id="`${prop.id}`" :value="postData[prop.slug]" :label="prop.name" :items="prop.enums ?? []" item-text="value" item-value="id"
                                                         @input="onValueChange(prop, $event)" :error="!valid"></SelectInput>
                                            <v-checkbox v-else-if="prop.type === 'boolean'" :id="`${prop.id}`" :value="postData[prop.slug]" :label="prop.name" @input="onValueChange(prop, $event)" @change="onValueChange(prop, $event)"
                                                        :error="!valid"></v-checkbox>
                                            <template v-else-if="prop.type === 'datetime'">
                                                <DatetimePickerText
                                                    :id="`${prop.id}`"
                                                    :value="postData[prop.slug]"
                                                    :label="prop.name"
                                                    @input="onValueChange(prop, $event)"
                                                ></DatetimePickerText>
                                            </template>
                                            <template v-else-if="prop.type === 'date'">
                                                <DatePickerText
                                                    :id="`${prop.id}`"
                                                    :value="postData[prop.slug]"
                                                    :label="prop.name"
                                                    @input="onValueChange(prop, $event)"
                                                ></DatePickerText>
                                            </template>
                                            <SelectInput v-else-if="prop.type === 'list'" :id="`${prop.id}`" :value="postData[prop.slug]" :label="prop.name" :items="prop.enums ?? []" @input="onValueChange(prop, $event)"
                                                         :error="!valid"></SelectInput>
                                            <template v-else-if="prop.type === 'dictionary'">
                                                <Dictionary :prop="prop" :id="`${prop.id}`" :value="postData[prop.slug]" :label="prop.name" :items="prop.enums ?? []" item-text="value" item-value="id" @input="onValueChange(prop, $event)" :error="!valid"></Dictionary>
                                            </template>
                                            <div v-else>
                                                <v-alert type="error" outlined>не известный тип '{{ prop.type }}'</v-alert>
                                            </div>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </template>

                            <v-row>
                                <v-col>
                                    <Btn @click="saveItem">{{ $t('save') }}</Btn>
                                </v-col>
                            </v-row>
                        </ValidationObserver>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-container>
</template>
<script>
import {mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import SettingMenu from "../components/SettingMenu";
import TextInput from "../components/Form/TextInput";
import Btn from "@/components/Form/Btn.vue";
import DatetimePickerText from "@/components/DatetimePickerText.vue";
import DatePickerText from "@/components/DatePickerText.vue";
import Dictionary from "@/components/Eav/Dictionary.vue";
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import SelectInput from "@/components/Form/SelectInput.vue";
import WithEntityType from "@/plugins/mixins/WithEntityType";
import WithEntity from "@/plugins/mixins/WithEntity";
import WithEntityProperties from "@/plugins/mixins/WithEntityProperties";
import _ from "lodash";


export default {
    name: "EavEntityForm",

    props: {
        slug: {
            type: String,
            required: true
        },
        uuid: {
            type: String,
            required: true
        }
    },

    directives: {
        mask,
    },

    mixins: [
        WithEntityType,
        WithEntity,
        WithEntityProperties,
    ],

    components: {
        SettingMenu,
        SelectInput,
        TextInput,
        DatetimePickerText,
        DatePickerText,
        Dictionary,
        ValidationObserver,
        ValidationProvider,
        Btn
    },

    watch: {
        uuid() {
            this.init();
        },
        slug() {
            this.init();
        }
    },

    data() {
        return {
            properties: [],
            type: null,
            elementData: {},
            postData: {},
            loading: false,
        };
    },

    computed: {
        ...mapGetters(['lang', 'listLanguageNames']),
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.loading = true;
            let typePromise = this.fetchEavEntityTypeBySlug(this.slug).then((type) => {
                this.type = type;
            });

            let itemPromise = this.fetchEavEntityTypePropertyByEntitySlug(this.slug).then((properties) => {
                this.properties = properties;
            });

            Promise.all([typePromise, itemPromise]).then(() => {
                if (this.uuid !== 'create') {
                    this.fetchEavEntity(this.slug, this.uuid).then((element) => {
                        let postData = {};
                        this.elementData = element;

                        this.properties.forEach((prop, index) => {
                            let elementProperty = element.properties.find((p) => p.id === prop.id);

                            if (elementProperty === undefined) {
                                postData[prop.slug] = null;
                            } else {
                                if(elementProperty.type === 'dictionary') {
                                    postData[prop.slug] = elementProperty.value?.dictionary_item ?? null;
                                } else if(elementProperty.type === 'date') {
                                    postData[prop.slug] = this.$moment(elementProperty.value?.value).format('YYYY-MM-DD') ?? null;
                                } else {
                                    postData[prop.slug] = elementProperty.value?.value ?? null;
                                }
                            }
                            this.$set(this, 'postData', postData);
                        });
                    }).finally(() => {
                        this.loading = false;
                    });
                } else {
                    this.loading = false;
                }
            })
        },

        onValueChange(prop, value) {
            let postData = this.postData

            // if(prop.type === 'dictionary') {
            //     postData[prop.slug] = value.id;
            // } else {
                postData[prop.slug] = value;
            // }

            this.$set(this, 'postData', postData);
        },

        saveItem() {
            if (this.uuid === 'create') {
                this.postItem();
            } else {
                this.putItem();
            }
        },

        putItem() {
            let data = this.preparePostData();
            this.loading = true;

            this.$http.put(`admin/eav/${this.slug}/${this.uuid}/property`, data)
                .then(response => {
                    this.$toastr.success(this.$t('eav_entity.updated'));
                })
                .catch(errorResp => {
                    this.$toastr.error(this.$t('eav_entity.error'));
                }).finally(() => {
                    this.loading = false;
                });
        },

        postItem() {
            let data = this.preparePostData();

            this.$http.post(`admin/eav/${this.slug}/property`, data)
                .then(response => {
                    this.$router.push({
                        name: 'eav.entity.edit',
                        params: {
                            slug: this.slug,
                            uuid: response.body.data.uuid
                        }
                    })
                })
                .catch(errorResp => {
                    this.$toastr.error(this.$t('eav_entity.error'));
                }).finally(() => {
                    this.loading = false;
                });
        },

        preparePostData () {
            let postData = {};

            this.properties.forEach((prop, index) => {
                let v = this.postData[prop.slug];

                if(prop.type === 'dictionary') {
                    postData[prop.slug] = v ? v.id : null;
                } else {
                    postData[prop.slug] = v;
                }

            });

            return postData;
        },
    }
}
</script>
