<script>
export default {
    name: "AutocompleteInput",
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        searchInput: {
            required: false,
            default: ''
        },
        value: {
            required: false,
            default: ''
        },
        items: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            required: false
        },

        placeholder: {
            type: String,
            required: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        color: {
            type: String,
            default: 'primary'
        },

        hideLabel: {
            type: Boolean,
            default: false
        },

        id: {
            type: String,
            required: true
        },

        error: {
            type: Boolean,
            required: false,
            default: false
        },

        multiple: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        onInput(value) {
            console.log('onInput', value);
        }
    },
}
</script>

<template>
    <div class="select">
        <label class="input_label" v-if="!hideLabel" :for="`${id}`">{{ label }}</label>
        <v-autocomplete
            :value="value"
            :id="id"
            :items="items"
            :label="placeholder ?? label"
            :disabled="disabled"
            :multiple="multiple"
            :error="error"
            :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
            :color="color"
            hide-details
            outlined
            solo
            flat
            dense
            class="select__input"
            v-bind="$attrs"
            v-on="$listeners"
            @input="onInput"
        ></v-autocomplete>
    </div>
</template>

<style lang="scss">
.select {
    .select__input {
        &.v-text-field--outlined {
            fieldset{
                border-width: 0;
            }
        }
    }
}

.select{
    .error--text {
        &.v-text-field--outlined {
            fieldset {
                border-width: 2px;
            }
        }
    }
}
</style>
