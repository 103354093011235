<script>
import AutocompleteInput from "@/components/Form/AutocompleteInput.vue";
import debounce from "lodash/debounce";

export default {
    name: "Dictionary",
    components: {
        AutocompleteInput
    },

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        prop: {
            type: Object,
            required: true
        },

        value: {
            type: [Object],
            default: null
        }
    },

    data() {
        return {
            searchValue: null,
            items: [],
            loading: false,
            innerValue: null
        }
    },

    computed: {},

    watch: {
        searchValue: {
            handler: debounce(function (value) {
                if (value) {
                    this.search(value)
                }
            }),
        },

        value() {
            if(this.value) {
                this.items.push(this.value);
            }
        }
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {

        },

        clear() {
            this.items = [];
        },

        onClick() {
            // this.search(this.searchValue);
        },

        onFocus() {
            // this.search(this.searchValue);
        },

        search(search) {
            if (search !== this.value?.display_value) {
                if (this.prop.linked_entity_type && search) {
                    this.loading = true;
                    this.$http.get(`admin/${this.prop.linked_entity_type.slug}/property`, {
                        params: {
                            search: search
                        }
                    }).then(response => {
                        let items = response.body.data;

                        items.forEach((item) => {
                            item.display_value = item.properties.find((prop) => {
                                return prop.id === item.display_property.id
                            })?.value;
                        });

                        this.items = items;
                    }).finally(() => {
                        this.loading = false;
                    });
                } else {
                    this.$toastr.error('Linked entity type is not defined');
                }
            }
        },

        filter(item, queryText, itemText) {
            return true;
        },

        onInput(value) {
            this.$emit('input', value);
        },
    }
}
</script>

<template>
    <AutocompleteInput
        v-bind="$attrs"
        v-on="$listeners"
        :label="prop.name"
        :value="value"
        :items="items"
        :id="`${prop.id}`"
        :item-text="`display_value`"
        :item-value="`id`"
        :search-input.sync="searchValue"
        :loading="loading"
        :no-data-text="searchValue ? $t('nothing_found_by',{'search': searchValue}) : $t('nothing_found_name')"
        :filter="filter"
        @click:clear="items = []"
        @click="onClick"
        @focusin="onFocus"
        @input="onInput"
        autocomplete="off"
        clearable
        return-object
    ></AutocompleteInput>
</template>

<style scoped lang="scss">

</style>
