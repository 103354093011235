export default {
    methods: {
        fetchEavEntityTypeBySlug(slug) {
            return new Promise((resolve, reject) => {
                this.$http.get(`admin/eav/type/${this.slug}`)
                    .then(response => {
                        resolve(response.body.data)
                    })
                    .catch(errorResp => {
                        reject(errorResp)
                    });
            })
        }
    }
}
