<template>
    <div>
        <v-dialog v-if="displayDateTimePicker" v-model="displayDateTimePicker" @click:outside="closeDialog" :width="dialogWidth" content-class="date-time-picker">
            <v-card>
                <v-card-text class="px-0 py-0">
                    <v-date-picker
                        ref="date"
                        v-model="date"
                        v-bind="datePickerProps"
                        :locale="locale"
                        :min="minDate"
                        :max="maxDate"
                        :hint="dateFormat"
                        @input="okHandlerAfter"
                        first-day-of-week="1"
                        full-width
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
        <TextInput
            :id="id"
            v-bind="$attrs"
            :append-icon="appendIcon"
            :prepend-icon="prependIcon"
            :value="value"
            :label="$attrs.label"
            @click:clear="clearHandler"
            @click.stop="openDialog"
        >
            <template v-slot:progress>
                <slot name="progress">
                    <v-progress-linear color="primary" indeterminate absolute height="2"></v-progress-linear>
                </slot>
            </template>
        </TextInput>
    </div>
</template>
<script>
import TextInput from '@/components/Form/TextInput'

export default {
    name: 'date-picker-text',
    components: {
        TextInput
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        id: {
            type: String,
            required: true
        },
        value: {
            type: [Date, String],
            default: null
        },
        dialogWidth: {
            type: [Number, String],
            default: 290
        },
        dateFormat: {
            type: String,
            default: 'YYYY-MM-DD'
        },
        dateFormat2: {
            type: String,
            default: 'DD.MM.YYYY'
        },
        prependIcon: {
            type: String,
            default: undefined
        },
        appendIcon: {
            type: String,
            default: undefined
        },
        clearText: {
            type: String,
            default: 'CLEAR'
        },
        okText: {
            type: String,
            default: 'OK'
        },
        textFieldProps: {
            type: Object
        },
        datePickerProps: {
            type: Object
        },
        readonly: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: true
        },
        useSeconds: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        locale: {
            type: String,
            default: 'ru'
        },
        timezone: {
            type: String,
            default: 'utc'
        },
    },
    data() {
        return {
            activeTab: 0,
            date: null,
            minDate: null,
            maxDate: null,
            displayDateTimePicker: false,
        }
    },
    computed: {
        dateTimeFormatText() {
            return this.dateFormat2;
        },

        formattedDatetimeText() {
            //return this.selectedDatetime ? this.$moment.tz(this.selectedDatetime, this.timezone).format(this.dateTimeFormat) : ''
            return this.selectedDatetime ? this.$moment(this.selectedDatetime).format(this.dateTimeFormatText) : ''
        },

        dateTimeFormat() {
            return this.dateFormat;
        },

        formattedDatetime() {
            //return this.selectedDatetime ? this.$moment.tz(this.selectedDatetime, this.timezone).format(this.dateTimeFormat) : ''
            return this.selectedDatetime ? this.$moment(this.selectedDatetime).format(this.dateTimeFormat) : ''
        },

        selectedDatetime() {
            if (this.date && this.time) {
                let datetimeString = this.date + ' ' + this.time
                //return this.$moment.tz(datetimeString, this.timezone).format(this.dateTimeFormat)
                return this.$moment(datetimeString).format(this.dateTimeFormat)
            } else {
                return null
            }
        },

        dateSelected() {
            return !this.date
        }
    },
    watch: {
        value: function (val) {
            this.init()
        },
        maxDateTime: function (nval, oval) {
            if (nval && nval !== oval) {
                //this.init()
                this.maxDate = this.$moment(this.maxDateTime).format(this.dateFormat)
            }
        },
        minDateTime: function (nval, oval) {
            if (nval && nval !== oval) {
                //this.init()
                this.minDate = this.$moment(nval).format(this.dateFormat)
            }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            if (this.minDateTime) {
                this.minDate = this.$moment(this.minDateTime).format(this.dateFormat)
                if (!this.maxDateTime) {
                    this.maxDate = null
                }
            }
            if (this.maxDateTime) {
                if (!this.minDateTime) {
                    this.minDate = null
                }
                this.maxDate = this.$moment(this.maxDateTime).format(this.dateFormat)
            }
            if (!this.value) {
                return
            }
            let initDateTime
            if (this.value instanceof Date) {
                initDateTime = this.value
            } else if (typeof this.value === 'string' || this.value instanceof String) {
                initDateTime = this.$moment(this.value).format(this.dateTimeFormat)
            }
            this.date = this.$moment(initDateTime).format(this.dateFormat)

        },
        okHandlerAfter(value) {
            this.$emit('input', value)
            this.closeDialog();
        },
        clearHandler() {
            this.date = null
            this.$emit('input', null)
        },
        openDialog() {
            if (this.readonly) {
                return
            }
            this.displayDateTimePicker = true
        },
        closeDialog() {
            this.displayDateTimePicker = false;
            this.$emit('closeDialogDateTimePicker');
        }
    }
}
</script>
<style>
.date-time-picker .v-picker__title {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.date-time-picker .v-slide-group__prev.v-slide-group__prev--disabled {
    display: none !important;
}
</style>
